import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const WWGuidesResonatorsPro: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ww-guide'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/wuthering-waves/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Resonator Progression</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ww/categories/category_reso2.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Resonator Progression</h1>
          <h2>
            How can you make your Resonators (characters) stronger in Wuthering
            Waves.
          </h2>
          <p>
            Last updated: <strong>04/05/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Resonator Progression" />
        <StaticImage src="../../../images/ww/generic/reso_1.webp" alt="Guide" />
        <p>
          Upgrading your Resonators in Wuthering Waves will enhance their combat
          capabilities, allowing you to challenge and overcome the more
          difficult content that the game offers.
        </p>
        <p>
          The main ways for a Resonator to grow stronger in Wuthering Waves are:
        </p>
        <ul>
          <li>Increasing the Resonator level</li>
          <li>Upgrading the Resonator’s Fortes</li>
          <li>Unlocking the Resonator’s Resonance Chain</li>
          <li>Equipping Weapons</li>
          <li>Equipping Echoes</li>
        </ul>
        <SectionHeader title="Character Level and Ascension" />
        <p>
          Each Resonator in Wuthering Waves has a Resonator Level ranging from 1
          to 90 and gains additional stats as they level up.
        </p>
        <p>
          Once a Resonator reaches certain level milestones, they will need to
          be ascended in order to increase their level further. Resonators start
          with a base max Level of 20. Each time you ascend a Resonator, their
          level cap will increase by 10 up to a maximum of 90. To ascend a
          Resonator, you will need to gather materials from Boss Challenges and
          collect materials from enemies and plants in the overworld.
        </p>
        <p>
          Also, note that your Union Level (Account Level) will also determine
          how many times you can ascend a Resonator. Even if you have all of the
          required ascension materials, you will not be able to ascend a
          Resonator past a certain Level unless your account is also of the
          appropriate Union Level.
        </p>
        <SectionHeader title="Fortes" />
        <StaticImage src="../../../images/ww/generic/fortes.webp" alt="Guide" />
        <p>
          <strong>
            Fortes are a skill upgrade tree that each Resonator has
          </strong>
          . Unlocking and upgrading the various nodes in a Resonator’s Forte
          will provide them with stat bonuses, extra passive abilities, and
          enhancements to their 5 Skills.
        </p>
        <p>
          Fortes can be upgraded by using materials obtained by completing
          Forgery Challenges, Weekly Challenges, and killing overworld enemies.
          Also, note that the ability to upgrade Fortes is tied to your
          Resonator’s Ascension, so you will need to level your Resonator first
          before being able to upgrade their Fortes.
        </p>
        <SectionHeader title="Resonance Chain / Wavebands" />
        <StaticImage src="../../../images/ww/generic/reso_2.webp" alt="Guide" />
        <p>
          Resonance Chain enhances Resonator’s abilities or provides them with
          new passive bonuses. Each Resonator has 6 Resonance Chain upgrades
          with each upgrade providing a different bonus.
        </p>
        <p>
          Resonance Chain upgrades can be unlocked by obtaining duplicate copies
          (called Wavebands) of the Resonator from Convenes (the gacha system)
          or through in-game missions and events. Though compared to other
          games, you can also{' '}
          <strong>buy Wavebands from the in-game shop</strong> using a currency
          you obtain from pulling - but the shop limits the amount of Wavebands
          you can buy to 2 per character (and this includes the current rate-up
          characters).
        </p>
        <p>
          The only exception to this is the Main Character. You can obtain their
          Wavebands via in-game missions and Relic Merchant rewards.
        </p>
        <SectionHeader title="Weapons" />
        <StaticImage src="../../../images/ww/generic/reso_3.webp" alt="Guide" />
        <p>
          Weapons are a type of equipment that all Resonators can equip and they
          provide stat bonuses to the Resonator and a new passive.{' '}
          <strong>
            {' '}
            Similar to Resonators, Weapons also have a Level ranging from 1 to
            90
          </strong>{' '}
          . They can be leveled up and ascended as well. Doing so will increase
          the stat bonus that they provide to the equipped Resonator.
        </p>
        <p>
          Each Weapon also has a Weapon ability which is a passive bonus. A
          Weapon Ability starts at Rank 1 and can be upgraded to Rank 5 by using
          the <strong> Synthesis</strong> function.{' '}
        </p>
        <p>
          To use the Synthesis function, you will need to expend duplicate Light
          Cones of the one that you are upgrading. Each duplicate Weapon that
          you use will increase the Weapon’s Ability Rank by 1. Note that any
          Weapons used as a Synthesis material will be consumed and destroyed.
          Increasing the Rank of a Weapon Ability will enhance some or all of
          the numerical portion of the bonus that it provides.
        </p>
        <p>
          <strong>
            Keep in mind that Weapons can only be equipped if the Resonator can
            use that weapon type.
          </strong>
        </p>
        <SectionHeader title="Echoes" />
        <StaticImage src="../../../images/ww/generic/reso_4.webp" alt="Guide" />
        <p>
          <strong>
            Echoes are the other type of equipment in this game and serve the
            function of armor and accessories in a traditional RPG game.
          </strong>{' '}
          Equipping Echoes to your Resonator will provide them with a variety of
          stat increases from HP to ATK to Energy Regeneration. Echoes will
          provide your Resonators with the main bulk of their stat increases.{' '}
        </p>
        <ul>
          <li>
            Every Echo has an <strong>active Echo ability</strong>.
          </li>
          <li>
            <strong>There are 5 total Echo slots</strong>. The Echo equipped in
            the first slot will have its active Echo Ability available for you
            to use. The order of the rest does not matter.
          </li>
          <li>
            Each Echo comes with a COST requirement that ranges between 1 to 4
            depending on the Echo’s Class.{' '}
            <strong>
              Each Resonator has a COST limit of 10 at the start and it is
              possible to upgrade it to 12 through Data Dock upgrades.
            </strong>{' '}
            Resonators can only equip Echoes up to the COST limit.
          </li>
        </ul>
        <p>There are 4 different Echo Classes:</p>
        <ul>
          <li>
            <strong className="Common">Common Class</strong> (1-COST)
          </li>
          <li>
            <strong className="Elite">Elite Class</strong> (3-COST)
          </li>
          <li>
            <strong className="Overlord">Overlord Class</strong> (4-COST)
          </li>
          <li>
            <strong className="Calamity">Calamity Class</strong> (4-COST)
          </li>
        </ul>
        <p>
          Each Echo has 2 main stats and up to 5 sub-stats. Primary main stats
          differ while secondary main stats are set for each Echo Class.
          Depending on the Echo Class, the possible{' '}
          <strong>primary main stats</strong> are:{' '}
        </p>
        <ul>
          <li>
            <strong className="Common">Common Class</strong> - HP%, ATK%, DEF%
          </li>
          <li>
            <strong className="Elite">Elite Class</strong> - HP%, ATK%, DEF%,
            Energy Regen%, Elemental Damage Boost*
          </li>
          <li>
            <strong className="Overlord">Overlord Class</strong> - HP%, ATK%,
            DEF% Crit Rate%, Crit DMG%, Healing Bonus%
          </li>
          <li>
            <strong className="Calamity">Calamity Class</strong> - HP%, ATK%,
            DEF% Crit Rate%, Crit DMG%, Healing Bonus%
          </li>
        </ul>
        <p>
          *Note that Elemental Damage Boost will be one of the 6 possible
          elements. It is not an All Elemental Damage Boost.
        </p>
        <p>
          Depending on the Echo Class, the set{' '}
          <strong>secondary main stats</strong> are:
        </p>
        <ul>
          <li>
            <strong className="Common">Common Class</strong> - Flat HP
          </li>
          <li>
            <strong className="Elite">Elite Class</strong> - Flat ATK
          </li>
          <li>
            <strong className="Overlord">Overlord Class</strong> - Flat ATK
          </li>
          <li>
            <strong className="Calamity">Calamity Class</strong> - Flat ATK
          </li>
        </ul>
        <p>
          To learn more about Echoes, Echo Stats and Echo Sets, check our other
          guides:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Echo System"
            link="/wuthering-waves/guides/echoes-explained"
            image={
              <StaticImage
                src="../../../images/ww/categories/category_echo.png"
                alt="Echo system explained"
              />
            }
          />
          <CategoryCard
            title="Echo Sets"
            link="/wuthering-waves/guides/echo-sets"
            image={
              <StaticImage
                src="../../../images/ww/categories/category_echo4.png"
                alt="Echo sets"
              />
            }
          />
          <CategoryCard
            title="Echo Stats"
            link="/wuthering-waves/guides/echo-stats"
            image={
              <StaticImage
                src="../../../images/ww/categories/category_echo3.png"
                alt="Echo stats"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default WWGuidesResonatorsPro;

export const Head: React.FC = () => (
  <Seo
    title="Resonator Progression | Wuthering Waves | Prydwen Institute"
    description="How can you make your Resonators (characters) stronger in Wuthering Waves."
    game="ww"
  />
);
